import { render, staticRenderFns } from "./index.vue?vue&type=template&id=345561b2&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionHero: require('/codebuild/output/src1513447352/src/lusio/components/section/Hero.vue').default,SectionProjectsGrid: require('/codebuild/output/src1513447352/src/lusio/components/section/ProjectsGrid.vue').default,SectionMeetTheTeam: require('/codebuild/output/src1513447352/src/lusio/components/section/MeetTheTeam.vue').default,SectionMission: require('/codebuild/output/src1513447352/src/lusio/components/section/Mission.vue').default,Footer: require('/codebuild/output/src1513447352/src/lusio/components/global/Footer.vue').default,SmoothScroll: require('/codebuild/output/src1513447352/src/lusio/components/global/SmoothScroll.vue').default,CursorDraw: require('/codebuild/output/src1513447352/src/lusio/components/global/CursorDraw.vue').default,SEO: require('/codebuild/output/src1513447352/src/lusio/components/global/SEO.vue').default,PreviewModeWrapper: require('/codebuild/output/src1513447352/src/lusio/components/global/PreviewModeWrapper.vue').default})
